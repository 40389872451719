


































































































































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import SubscriptionPlanRedesign from "@/components/add-venue/SubscriptionPlanRedesign.vue";
import AdditionalServices from "@/components/add-venue/AdditionalServices.vue";
import PaymentPeriod from "@/components/add-venue/PaymentPeriod.vue";
import MusicStreamingMethod from "@/components/add-venue/MusicStreamingMethod.vue";
import DiscountCode from "@/components/add-venue/DiscountCode.vue";
import SummaryPriceRedesign from "@/components/add-venue/SummaryPriceRedesign.vue";
import BankAccountCard from "@/components/payments/BankAccountBlock.vue";
import PaymentCardBlockRedesign from  "@/components/payments/PaymentCardBlockRedesign.vue";
import AddBankAccountModal from "@/components/modals/AddBankAccountModal.vue";
import AddPaymentCardModal from "@/components/modals/AddPaymentCardModal.vue";
import {mapActions, mapGetters} from "vuex";
import { EventPlanData, SubscriptionProductsData, PaymentCardData, BankAccountData } from "@/types";
import {formatUnitPrice} from "@/utils/helpers";
import i18n from "@/i18n/i18n";
import _ from 'lodash';
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import { errorMessage } from "@/utils/messages-handlers";
Object.defineProperty(Vue.prototype, '$_', { value: _ });

export default Vue.extend({
  name: "add-new-venue" as string,

  components: {
    Layout,
    "subscription-plan-redesign": SubscriptionPlanRedesign,
    "additional-services": AdditionalServices,
    "payment-period": PaymentPeriod,
    "music-streaming-method": MusicStreamingMethod,
    "discount-code": DiscountCode,
    "summary-price-redesign": SummaryPriceRedesign,
    "add-bank-account-modal": AddBankAccountModal,
    "add-payment-card-modal": AddPaymentCardModal,
    "bank-account-card": BankAccountCard,
    "payment-card-block-redesign": PaymentCardBlockRedesign,
  },

  data() {
    return {
      title: "Add New Venue",
      chargePrice: "44,80",
      vat: 20,
      status: "not_accepted",
      audioMessages: [],
      additionalServicesIds: [],
      webstreamer: 0,
      selectedSubscriptionProduct: null,
      checkboxGdpr: false,
      isSubmitted: false,
      stripe: null,
      selectedPaymentType: null,
      selectedPaymentMethod: null,
      setLoader: false,
      i18: i18n,
      discountCodePercentage: 0,
      discountCodeCode: "",
      webGeneralTermsUrl: process.env.VUE_APP_SONGOROO_WEB_GENERAL_TERMS_OF_USE,
      webGeneralTermsUrlSk: process.env.VUE_APP_SONGOROO_WEB_GENERAL_TERMS_OF_USE_SK,
      webPolicyPrivacyUrl: process.env.VUE_APP_SONGOROO_WEB_PRIVACY_POLICY_LINK,
      webPolicyPrivacyUrlSk: process.env.VUE_APP_SONGOROO_WEB_PRIVACY_POLICY_LINK_SK,
    }
  },

  async mounted(): Promise<void> {
    await this.setSubscriptionProducts();
    await this.setPaymentCards();
    await this.setTaxRates();
    await this.setTaxPricePeriodTranslation();
    this.setCreateVenueLoader(false);
    await this.setBillingIntervalOnLoad()
    await this.setBankAccounts();

    if (this.creditCards.length === 0) {
      this.createStripeCustomer();
    }
  },

  computed: {
    ...mapGetters("venueModule", {
      newVenueSubPlan: "GET_NEW_VENUE_SUB_PLAN",
      venueState: "GET_VENUE_STATE",
      venueRegisterData: "GET_VENUE_REGISTER_DATA",
      newVenueId: "GET_NEW_VENUE_ID",
      errorOnInvalidCreditCard: "GET_ERROR_ON_INVALID_CREDIT_CARD",
      createVenueLoader: "GET_CREATE_VENUE_LOADER",
      loader: "GET_VENUE_LOADER"
    }),
    ...mapGetters("venueSubscriptionModule", {
      subscriptionProducts: "GET_FILTERED_SUBSCRIPTION_PRODUCTS",
      taxRate: "GET_TAX_RATE",
      recurringInterval: "GET_SELECTED_RECURRING_INTERVAL",
      allSubscriptionProducts: "GET_ALL_SUBSCRIPTION_PRODUCTS",
      paymentInterval: "GET_RECURRING_INTERVAL",
      invalidDiscountCode: "GET_INVALID_DISCOUNT_CODE",
      stripeSubscriptionResponse: "GET_STRIPE_SUBSCRIPTION_RESPONSE"
    }),
    ...mapGetters("paymentModule", {
      allPaymentMethods: "GET_PAYMENT_METHODS",
      bankAccounts: "GET_BANK_ACCOUNTS",
      creditCards: "GET_PAYMENT_CARDS",
      stripeKey: "GET_STRIPE_PUBLISHABLE_KEY",
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID",
      taxIsNotValid: "TAX_IS_NOT_VALID",
      company: "GET_COMPANY"
    }),
    ...mapGetters("taxPricePeriodTranslationModule", {
      taxPricePeriodTranslation: "GET_TAX_PRICE_PERIOD_TRANSLATION"
    }),

    isGdprAccepted(): boolean {
      return this.checkboxGdpr;
    },
    isPaymentMethod(): boolean {
      return !!this.selectedPaymentMethod;
    },
    subscriptionProduct(): Array<SubscriptionProductsData> {
      return this.subscriptionProducts.filter(p => p.songorooServiceType === 1);
    },
    additionalSubscriptionProducts(): SubscriptionProductsData {
      return this.subscriptionProducts.filter(p => p.songorooServiceType === 2 && p.recurringInterval == this.paymentInterval && p.isActive == true);
    },
    finalPrice(): string {
      if (!this.allSubscriptionProducts || !this.selectedSubscriptionProduct) return "";

      // the price per month is always displayed, even if a variant of the year is selected
      const subscriptionProductMonthlyPrice = this.allSubscriptionProducts.find(s => s.productName === this.selectedSubscriptionProduct.productName && s.recurringInterval === this.paymentInterval).unitAmount;
      let audioMessagesTotal = 0;
      for (const additionalService of this.audioMessages) {
        audioMessagesTotal += additionalService.unitAmount
      }
      const taxMultiplier: number = this.taxIsNotValid ? 1.2 : 1;
      const discountCode: number = (100 - this.discountCodePercentage) / 100;

      const price = (!!this.selectedSubscriptionProduct ? subscriptionProductMonthlyPrice : 0) + (audioMessagesTotal != 0 ? audioMessagesTotal : 0);
      return formatUnitPrice(Number(price) * taxMultiplier * discountCode);
    },
    locale(): string{
      return i18n.locale
    },
    priceAndPeriodInformation(){
      if(!this.taxPricePeriodTranslation || !this.paymentInterval) return "";
      return this.locale == "en" ? this.taxPricePeriodTranslation.priceAndPeriodMonthEn : (this.taxPricePeriodTranslation.isPriceAndPeriodMonthTranslationCompleted ? this.taxPricePeriodTranslation.priceAndPeriodMonthSk : this.taxPricePeriodTranslation.priceAndPeriodMonthEn);
    },
    taxInfo(){
      if(!this.taxPricePeriodTranslation) return "";
      return this.locale == "en" ? this.taxPricePeriodTranslation.taxInformationEn : (this.taxPricePeriodTranslation.isTaxInformationCompleted ? this.taxPricePeriodTranslation.taxInformationSk : this.taxPricePeriodTranslation.taxInformationEn);
    },
  },

  methods: {
    ...mapActions("venueModule", {
      createVenue: "ADD_NEW_VENUE",
      createSubscriptionAfterUnsuccessfulAttempt: "CREATE_SUBSCRIPTION_AFTER_UNSUCCESSFUL_ATTEMPT",
      setCreateVenueLoader: "SET_CREATE_VENUE_LOADER"
    }),
    ...mapActions("venueSubscriptionModule", {
      setSubscriptionProducts: "FETCH_SUBSCRIPTION_PRODUCTS",
      setTaxRates: "FETCH_TAX_RATE",
      setRecurringInterval: "SET_RECURRING_INTERVAL",
      createVenueSubscriptionOnStripe: "CREATE_VENUE_SUBSCRIPTION_ON_STRIPE"
    }),
    ...mapActions("paymentModule", {
      setPaymentCards: "FETCH_PAYMENT_CARDS",
      setBankAccounts: "FETCH_BANK_ACCOUNTS",
      createStripeCustomer: "CREATE_STRIPE_CUSTOMER",
    }),
    ...mapActions("taxPricePeriodTranslationModule", {
      setTaxPricePeriodTranslation: "FETCH_TAX_PRICE_PERIOD_TRANSLATION"
    }),
    setAudioMessages(value: EventPlanData): void {
      this.audioMessages.push(value);
      this.additionalServicesIds.push(value.id)
    },
    removeAdditionalService(value: EventPlanData): void {
      const removeIndex = this.audioMessages.map(item => item.id).indexOf(value.id);
      ~removeIndex && this.audioMessages.splice(removeIndex, 1);
      ~removeIndex && this.additionalServicesIds.splice(removeIndex, 1);
    },
    setWebstreamer(value: boolean): void {
      this.webstreamer = value;
    },
    setSubscriptionProduct(value: SubscriptionProductsData): void {
      this.selectedSubscriptionProduct = value;
    },
    setPayment(value): void {
      if (value.paymentType === this.selectedPaymentType &&
        value.paymentId === this.selectedPaymentMethod
      ) {
        this.selectedPaymentType = null;
        this.selectedPaymentMethod = null;
      } else {
        this.selectedPaymentType = value.paymentType;
        this.selectedPaymentMethod = value.paymentId;
      }
    },
    async setBillingIntervalOnLoad(){
      if(!this.company){
        this.setRecurringInterval('month');
      } else{
        const recurringInterval = this.company.corporate ? (this.company.billing_interval === 1 ? "month" : "year") : 'month'
        this.setRecurringInterval(recurringInterval);
      }
    },
    async createNewVenue(): Promise<void> {
      this.isSubmitted = true;
      if (!this.isGdprAccepted || !this.isPaymentMethod || this.invalidDiscountCode) return;
      this.setLoader = true;

      let venueSubscriptionData: any = {
        stripeProductWithPriceId: this.selectedSubscriptionProduct.id,
        taxId: this.taxRate.id,
        venueOwnerId: this.companyId,
        paymentType: this.selectedPaymentType,
        paymentMethodId: this.selectedPaymentMethod,
        additionalServices: this.additionalServicesIds.join(' '),
        initialFreeTrialDays: 0,
        includedStreamingDevice: this.webstreamer,
        appliedDiscountCode: this.discountCodeCode,
        amount: this.selectedSubscriptionProduct.unitAmount,
        recurringInterval: this.selectedSubscriptionProduct.recurringInterval,
        startOfBillingPeriod: moment().unix(),
        errorOnInvalidCreditCard: this.errorOnInvalidCreditCard,
      }

      if(this.company.corporate){
        await this.createVenue(venueSubscriptionData);
      } else {
        this.setCreateVenueLoader(true);
        await this.createVenueSubscriptionOnStripe(venueSubscriptionData);
        venueSubscriptionData.subscriptionId = this.stripeSubscriptionResponse.stripeSubscriptionData.id
        if(this.stripeSubscriptionResponse.stripePaymentIntentData && this.stripeSubscriptionResponse.stripePaymentIntentData.status === "requires_action"){
          this.stripe = await loadStripe(this.stripeKey, {locale: this.$i18n.locale});
          const cardPaymentConfirmation = await this.stripe.confirmCardPayment(this.stripeSubscriptionResponse.stripePaymentIntentData.client_secret, {
            payment_method: this.stripeSubscriptionResponse.stripePaymentIntentData.payment_method,
            setup_future_usage: 'off_session'
          })
          if(cardPaymentConfirmation.error){
            errorMessage('We are unable to authenticate your payment method. Please choose a different payment method and try again.', 5000)
            this.setCreateVenueLoader(false);
          }
          if (cardPaymentConfirmation.paymentIntent) {
            this.setCreateVenueLoader(true);
            setTimeout(async () => {
              await this.createVenue(venueSubscriptionData)
            }, 5000)
          }
        } else {
          setTimeout(async () => {
            await this.createVenue(venueSubscriptionData)
          }, 5000)
        }
      }
      setTimeout(() => {
        this.isSubmitted = false;
      }, 6000)
    },
    onBillingPeriodicityChange(){
      this.audioMessages = [];
      this.additionalServicesIds = [];
    },
    discountCodeFound(value){
      if(_.isEmpty(value)){
        this.discountCodePercentage = 0;
        this.discountCodeCode = ""
      } else{
        this.discountCodePercentage = value.percentageDiscount;
        this.discountCodeCode = value.code
      }
    }
  },
  watch: {
    createVenueLoader(value) {
      if (value) {
        document.querySelector<HTMLElement>('body >*:not(iframe)').style.pointerEvents = 'none'
      } else {
        document.querySelector<HTMLElement>('body >*:not(iframe)').style.pointerEvents = null
      }
    },
    creditCards: {
      handler(cards: Array<PaymentCardData>): void {
        if (cards.length === 1) {
          let cardId: number = cards[0].id;
    
          this.setPayment({ paymentId: cardId, paymentType: 1 });
          this.selectedPaymentType = 1;
          this.selectedPaymentMethod = cardId;
        }
      },
    },
    bankAccounts: {
      handler(bankAccounts: Array<BankAccountData>): void {
        if (bankAccounts.length === 1) {
          let bankAccountId: number = bankAccounts[0].id;
    
          this.setPayment({ paymentId: bankAccountId, paymentType: 2 });
          this.selectedPaymentType = 2;
          this.selectedPaymentMethod = bankAccountId;
        }
      }
    },
  }
})
